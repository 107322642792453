import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { CenteredOnMobile } from '../Hero/styles'

export const CtaContainer = styled(CenteredOnMobile)`
  text-align: left;
  a + a {
    margin-left: 16px;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    a {
      text-align: center;
      display: block;
    }
    a + a {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
`
export const Container = styled.div`
  position: relative;
  .slick-slider > .slick-list {
    height: auto !important;
  }
  .slick-slide div {
    outline: none;
  }
  .slick-slide {
    min-height: auto;
  }
  .slick-dots {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    li {
      margin: 0px 20px;
      width: 100px;
      height: 4px;
      padding: 0px;
    }
  }
  .slick-dots {
    .slick-active button {
      background: #c6c6c6;
    }
    button {
      background: #444444;
      width: 100%;
      padding: 0px;
      height: 4px;
      ::before {
        display: none;
      }
    }
    button:after {
      content: '';
      position: absolute;
      left: 0px;
      top: -15px;
      width: 100px;
      height: 40px;
      background: transparent;
    }
  }
  @media (max-width: ${breakpoints.xl}px) {
    .slick-dots {
      .slick-active button {
        background: #444444;
      }
      button {
        background: #c6c6c6;
      }
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    .slick-dots {
      bottom: 300px;
    }
  }
  @media (max-width: ${breakpoints.m}px) {
    .slick-dots {
      bottom: 325px;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    .slick-dots {
      position: absolute;
      height: 3px;
      top: 55%;
      button {
        width: 40px;
      }
      button:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 30px;
      }
      li {
        width: 10px;
      }
    }
    #slide-0,
    #slide-1 {
      width: 90%;
      margin: auto;
    }
  }
  @media (max-width: 550px) {
    .slick-dots {
      top: 340px;
    }
  }
  @media (max-width: 530px) {
    .slick-dots {
      top: 320px;
    }
  }
  @media (max-width: 500px) {
    .slick-dots {
      top: 300px;
    }
  }
  @media (max-width: 470px) {
    .slick-dots {
      top: 280px;
    }
  }
  @media (max-width: 440px) {
    .slick-dots {
      top: 260px;
    }
  }
  @media (max-width: 400px) {
    .slick-dots {
      top: 250px;
    }
  }
  @media (max-width: 390px) {
    .slick-dots {
      top: 240px;
    }
  }
  @media (max-width: 375px) {
    .slick-dots {
      top: 230px;
    }
  }
  @media (max-width: 360px) {
    .slick-dots {
      top: 215px;
    }
  }
  @media (max-width: 340px) {
    .slick-dots {
      top: 205px;
    }
  }
`
export const Header = styled.h1`
  font-size: 56px;
  font-weight: 500;
  line-height: 1;
  margin: 0px;
  text-align: left;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 28px;
    line-height: 32px;
  }
`
export const Subheading = styled.h1`
  font-family: 'FilsonProBook';
  font-size: 26px;
  line-height: 28px;
  margin: 10px 0px 10px;
  text-align: left;
  @media (max-width: ${breakpoints.xl}px) {
    font-size: 24px;
    line-height: 26px;
  }
  @media (max-width: ${breakpoints.l}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 16px;
    line-height: 24px;
    margin: 0px;
  }
`

export const TextBox = styled.div`
  width: 600px;
  margin: 10px 0px 40px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 415px;
  }
  @media (max-width: ${breakpoints.l}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 10px 0px 10px;
  }
`
export const Row = styled.div`
  position: relative;
  width: 100%;
  > #text {
    z-index: 101;
  }
  > #gradiant {
    z-index: 50;
  }
  > #video {
    z-index: -1;
  }
  @media (max-width: ${breakpoints.xl}px) {
    top: 0px;
  }
  @media (max-width: ${breakpoints.l}px) {
    padding-bottom: 0px;
  }
`
export const TextPosition = styled.div`
  position: absolute;
  top: 160px;
  width: 100%;
  @media (max-width: 1500px) {
    top: 120px;
  }
  @media (max-width: ${breakpoints.xl}px) {
    top: 80px;
  }
  @media (max-width: ${breakpoints.l}px) {
    position: relative;
    top: 40px;
    padding-bottom: 80px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding-bottom: 50px;
  }
  @media (max-width: ${breakpoints.s}px) {
    top: 0px;
    padding-bottom: 0px;
  }
`
export const TextContainer = styled.div`
  width: 90%;
  margin: auto;
  position: relative;
  z-index: 100 !important;
  @media (max-width: ${breakpoints.l}px) {
    .yotpo-reviews-star-ratings-widget {
      padding-top: ${(props) => (props.isFrame2 ? '0px' : '20px')} !important;
      justify-content: center !important;
    }
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: ${(props) => (props.isFrame2 ? '4px' : '8px')} !important;
    .yotpo-reviews-star-ratings-widget {
      justify-content: center !important;
    }
  }
`

export const HeroVideoContainer = styled.div`
  width: 100%;
  img {
    width: 100%;
  }
  @media (max-width: ${breakpoints.l}px) {
    display: none;
  }
`

export const HeroMobileImageContainer = styled.div`
  display: none;
  @media (max-width: ${breakpoints.l}px) {
    display: block;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    margin-left: 5%;
  }
`
export const HeroVideo = styled.video`
  width: 100%;
  border-top-left-radius: 48px;
  border-bottom-left-radius: 48px;
  margin-left: 2px;
`
