/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Slider from 'react-slick'
import YotpoStars from 'src/components/YotpoStars'
import theme from 'src/utils/styles/theme'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Analytics from 'src/utils/Analytics'
import PropTypes from 'prop-types'
import { HOMEPAGE_PRODUCT_DETAILS, FRAME_2_PRODUCT_DETAILS } from './constants'
import { BuyNowLink } from '../Hero/styles'
import frame2hero from '../../videos/frame-2-hero.mp4'
import frame2heroMobile from '../../videos/frame-2-hero-mobile.mp4'

import {
  Container,
  Header,
  Subheading,
  TextBox,
  Row,
  TextContainer,
  TextPosition,
  HeroVideoContainer,
  CtaContainer,
  HeroMobileImageContainer,
  HeroVideo,
} from './styles'

import { Intro } from '../CalMaxHero/styles'
import { CAL_BUY_LINK } from '../../utils/constants'

export default function HomepageHero({ isFrame2 }) {
  const { frameBackground, frame2Background, frameBackgroundMobile, frame2BackgroundMobile } =
    useStaticQuery(graphql`
      query HomepageHeroQuery {
        frameBackground: file(relativePath: { eq: "homepage/frame-cal-background.jpg" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        frame2Background: file(relativePath: { eq: "homepage/baby-dog-in-wood-frame.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        frameBackgroundMobile: file(
          relativePath: { eq: "homepage/frame-cal-background-mobile.png" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
        frame2BackgroundMobile: file(
          relativePath: { eq: "homepage/baby-dog-in-wood-frame-mobile.webp" }
        ) {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
          }
        }
      }
    `)

  const DESKTOP_IMAGES = {
    Frame: frameBackground,
    'Frame 2': frame2Background,
  }
  const MOBILE_IMAGES = {
    Frame: frameBackgroundMobile,
    'Frame 2': frame2BackgroundMobile,
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 5000,
    adaptiveHeight: true,
    arrows: false,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 5000,
          adaptiveHeight: true,
          arrows: false,
          lazyLoad: false,
        },
      },
    ],
  }
  const filteredSlides = isFrame2 ? FRAME_2_PRODUCT_DETAILS : HOMEPAGE_PRODUCT_DETAILS
  return (
    <Container>
      <Slider {...settings}>
        {filteredSlides.map((slide, i) => (
          <Row key={i}>
            <HeroVideoContainer>
              {slide.name === 'Frame 2' && !isFrame2 ? (
                <HeroVideo playsInline autoPlay muted loop controls={false}>
                  <source src={frame2hero} type="video/mp4" />
                </HeroVideo>
              ) : (
                <GatsbyImage
                  image={DESKTOP_IMAGES[slide.name].childImageSharp.gatsbyImageData}
                  alt={`Skylight ${slide.name}`}
                />
              )}
            </HeroVideoContainer>
            <HeroMobileImageContainer>
              {slide.name === 'Frame 2' && !isFrame2 ? (
                <HeroVideo playsInline autoPlay muted loop controls={false}>
                  <source src={frame2heroMobile} type="video/mp4" />
                </HeroVideo>
              ) : (
                <GatsbyImage
                  image={MOBILE_IMAGES[slide.name].childImageSharp.gatsbyImageData}
                  alt={`Skylight ${slide.name}`}
                />
              )}
            </HeroMobileImageContainer>
            <TextPosition id="text">
              <TextContainer isFrame2={isFrame2}>
                <YotpoStars color={theme.orange} textcolor="#444444" productId={slide.productId} />
                {slide.heading === 'Change the way you share memories.' && (
                  <Intro>Just released: the new Frame 2</Intro>
                )}
                <TextBox>
                  <Header>{slide.heading}</Header>
                  <Subheading>{slide.subheading}</Subheading>
                </TextBox>
                <CtaContainer>
                  <BuyNowLink onClick={() => Analytics.track(slide.analyticsEvent)} to={slide.link}>
                    {slide.ctaText}
                  </BuyNowLink>
                  {!isFrame2 && (
                    <BuyNowLink
                      onClick={() =>
                        Analytics.track('Clicked Buy Now Calendar Hero on Homepage CTA')
                      }
                      to={CAL_BUY_LINK}
                    >
                      Buy Calendar
                    </BuyNowLink>
                  )}
                </CtaContainer>
              </TextContainer>
            </TextPosition>
          </Row>
        ))}
      </Slider>
    </Container>
  )
}

HomepageHero.propTypes = {
  isFrame2: PropTypes.bool,
}
