import { FRAME_2_BUY_LINK } from '../../utils/constants'

export const HOMEPAGE_PRODUCT_DETAILS = [
  {
    name: 'Frame',
    heading: 'Your perfect gift',
    subheading: `The bestselling All-In-One Smart Family Calendar and Digital Photo Frame.`,
    link: `${FRAME_2_BUY_LINK}`,
    ctaText: 'Buy Frame',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Homepage CTA',
  },
]
export const FRAME_2_PRODUCT_DETAILS = [
  {
    name: 'Frame 2',
    heading: 'Change the way you share memories.',
    subheading: `Our bestselling smart photo frame just got even better. And with our new Snap Frames, you don't have to choose a color - you can have them all.`,
    link: `${FRAME_2_BUY_LINK}`,
    ctaText: 'Buy Now',
    productId: '7073751498804',
    analyticsEvent: 'Clicked Buy Now Frame 2 Hero on Frame 2 CTA',
  },
]
